'use client'
import type { ChangeEvent, ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  Configure,
  useHits,
  InstantSearch,
  useInstantSearch,
  useSearchBox,
} from 'react-instantsearch'
import type { Hit } from 'instantsearch.js/es/types/results'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'

import type { Menu } from '@lib/types'
import NavLink from '@components/nav-link'
import FieldGroup from '@components/field-group'
import TextField from '@components/text-field'
import { debounceQueryHook, getSearchClient } from '@lib/search'
import { searchInputDebounceMs, searchMinimumQueryLength } from '@lib/constants'
import { Button, Container, Icon, Typography } from '@shc/ui'
import { useAppConfig } from '@components/client-wrapper'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import type { TPlaceContext, TProviderContext } from '@lib/analytics'
import Analytics from '@components/analytics'

const queryHook = debounceQueryHook(searchInputDebounceMs)

interface SearchProps extends ComponentPropsWithoutRef<'section'> {
  searchUrl?: string
  baseSearchFilter?: string
}

const Search = ({
  searchUrl = '/search',
  baseSearchFilter = '',
  className,
  children,
  ...props
}: SearchProps) => {
  const appConfig = useAppConfig()
  const algoliaKey = appConfig.configs.algolia.algoliaKey
  const indexName = appConfig.configs.algolia.globalIndexName
  const searchClient = getSearchClient(algoliaKey)
  const { results, status } = useInstantSearch()
  const { nbHits } = results
  const { query, refine } = useSearchBox({ queryHook })
  const [queryValue, setQueryValue] = useState<string>(query)
  const { items } = useHits()
  const { track } = useAnalytics()

  // we can't use the global snowplow context here because we may already be on
  // a search page when the takeover is opened and that would cause conflicts
  const [baseSearchContexts, setBaseSearchContexts] = useState<SPContext[]>([])

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value
    setQueryValue(q)
    refine(q)
  }

  const hasMinQueryLen = queryValue.length >= searchMinimumQueryLength
  const showLoadState = hasMinQueryLen && status === 'stalled'
  const showInitialState = queryValue.length < searchMinimumQueryLength
  const showNoResultsState = hasMinQueryLen && !showLoadState && nbHits === 0
  const showErrorState = status === 'error'
  const showResults = !showLoadState && !showErrorState && !showNoResultsState && !showInitialState

  useEffect(() => {
    // track analytics on search query
    if (hasMinQueryLen) {
      const newSearchContext: SPContext[] = [
        {
          name: 'modal',
          data: {
            modal_name: 'Search takeover',
          },
        },
        {
          name: 'search',
          data: {
            search_query: queryValue,
            search_location: null,
            search_index: 'crawler_sharp_com',
            search_result_count: results.nbHits,
            search_facets: '{}',
          },
        },
      ]

      // this will be used for the onClick event
      setBaseSearchContexts(newSearchContext)

      // track the search query
      track({
        event: {
          name: 'search_query',
          data: {
            search_query_trigger: 'onKeyUp',
          },
        },
        contexts: newSearchContext,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.hits])

  return (
    <Container as="section" className={clsx('grid grid-cols-12 gap-x-5', className)} {...props}>
      {/* FORM */}
      <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 pt-12 lg:pt-15 flex flex-col gap-5">
        <Typography variant="h4" as="h1" className="text-primary">
          Search
        </Typography>

        {/* SEARCH BOX */}
        <form autoComplete="off" action={searchUrl}>
          <InstantSearch searchClient={searchClient} indexName={indexName}>
            <Configure clickAnalytics={true} hitsPerPage={10} filters={baseSearchFilter} />
            <FieldGroup
              button={
                <Button type="submit" aria-label="Search" width="auto" className="gap-0">
                  <Icon className="h-5" icon="magnifying-glass" />
                </Button>
              }>
              <TextField
                type="search"
                value={queryValue}
                onChange={handleSearch}
                autoFocus={true}
                autoComplete="off"
                autoCorrect="off"
                maxLength={512}
                spellCheck={false}
                name="q"
                label="Search term"
                hideLabel={true}
              />
            </FieldGroup>
          </InstantSearch>
        </form>
      </div>

      <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 pt-12">
        {/* ERROR */}
        {showErrorState && (
          <Typography variant="h3" as="h2">
            We are sorry an error has occurred while trying to search, please try again.
          </Typography>
        )}

        {/* NO RESULTS STATE */}
        {showNoResultsState && (
          <Typography variant="h3" as="h2">
            No results matched your search term.
          </Typography>
        )}

        {/* LOADING STATE */}
        {showLoadState && (
          <Typography variant="h3" as="h2">
            Loading...
          </Typography>
        )}

        {/* RESULTS */}
        {showResults && (
          <ul className="list-none columns-1 lg:columns-2 gap-15">
            {items.map((hit, index) => {
              const { url, title } = hit as Hit
              // results.hit specific contexts
              const hitSearchContexts: SPContext[] = [
                {
                  name: 'component',
                  data: {
                    component_text: `${hit.title}`,
                    component_url: `${hit.url}`,
                  },
                },
              ]

              // provider context
              if (hit.contentType == 'Physicians') {
                const providerContext: TProviderContext = {
                  name: 'provider',
                  data: {
                    provider_id: Number(hit.providerId ?? -1),
                    provider_name: String(hit.title),
                  },
                }
                hitSearchContexts.push(providerContext)
              }

              // place context
              if (hit.contentType == 'Locations') {
                const placeContext: TPlaceContext = {
                  name: 'place',
                  data: {
                    place_id: Number(hit.id ?? -1),
                    place_name: String(hit.title),
                  },
                }
                hitSearchContexts.push(placeContext)
              }

              return (
                <li key={index} className={clsx(index > 4 && 'hidden lg:list-item')}>
                  {/* track analytics on result click */}
                  <Analytics
                    click={{
                      name: 'component_click',
                      data: {},
                    }}
                    contexts={[...baseSearchContexts, ...hitSearchContexts]}>
                    <NavLink as="a" href={url} className="max-w-full" {...props}>
                      <span className="text-sm font-semibold block whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {title}
                      </span>
                    </NavLink>
                  </Analytics>
                </li>
              )
            })}
          </ul>
        )}

        {/* MENU */}
        {showInitialState && children}
      </div>
    </Container>
  )
}

export interface SearchTakeoverProps extends ComponentPropsWithoutRef<'div'> {
  menu: Menu
  isVisible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  activePathname: string | null
  searchUrl?: string
  baseSearchFilter?: string
}

const SearchTakeover = ({
  menu,
  isVisible,
  className,
  setVisible,
  activePathname,
  searchUrl,
  baseSearchFilter,
  ...props
}: SearchTakeoverProps) => {
  const appConfig = useAppConfig()
  const algoliaKey = appConfig.configs.algolia.algoliaKey
  const indexName = appConfig.configs.algolia.globalIndexName
  const searchClient = getSearchClient(algoliaKey)

  // Close takeover on esc key
  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setVisible(false)
      }
    },
    [setVisible]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  return (
    <Transition
      as="section"
      className={clsx('fixed inset-0 z-50 transition-all origin-top', className)}
      show={isVisible}
      enter="transition ease-in-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      role="dialog"
      aria-hidden={!isVisible}
      {...props}>
      {/* HEADER */}
      <div className="w-full h-14 bg-primary-50">
        <Button
          color="transparent"
          size="sm"
          shape="circle"
          width="auto"
          aria-label="Close search"
          className="self-start m-2 float-right"
          onClick={() => setVisible(false)}>
          <Icon icon="xmark-large" className="h-4" />
        </Button>
      </div>

      {/* WRAPPER */}
      <div className="bg-white px-5 pb-20 min-h-screen w-full overflow-auto">
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <Configure clickAnalytics={true} hitsPerPage={10} filters={baseSearchFilter} />
          <Search searchUrl={searchUrl} baseSearchFilter={baseSearchFilter}>
            {/* MENU (Initial load state) */}
            <h2 className="text-lg lg:text-xl font-semibold text-primary">
              What can we help you find?
            </h2>
            <ul className="list-none columns-1 lg:columns-2 gap-15 pt-5">
              {menu.map(([link], index) => (
                <li key={index} className={clsx(index > 4 && 'hidden lg:list-item')}>
                  <NavLink
                    as="a"
                    href={link.route}
                    target={link.isInternal ? undefined : '_blank'}
                    rel={link.isInternal ? undefined : 'noopener noreferrer'}
                    active={link.route.toLowerCase() === activePathname}
                    aria-current={link.route.toLowerCase() === activePathname ? 'page' : undefined}
                    className="max-w-full">
                    <span className="text-sm font-semibold block whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {link.name}
                    </span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </Search>
        </InstantSearch>
      </div>
    </Transition>
  )
}

SearchTakeover.Search = Search

export default SearchTakeover
