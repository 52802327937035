import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES, MARKS, type Block, type Inline } from '@contentful/rich-text-types'
import type { Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Icon from '@cms/components/icon'

import { isInternalHref } from '@lib/mappers/menu-item-mapper'
import { contentTypeBasePathMap, indexPageSlug } from '@lib/constants'
import { Link, Typography } from '@shc/ui'

export const renderMark: Options['renderMark'] = {
  [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  [MARKS.ITALIC]: (text) => <em>{text}</em>,
}

export const renderNode: Options['renderNode'] = {
  [BLOCKS.HEADING_1]: (_node, children) => <Typography variant="h1">{children}</Typography>,
  [BLOCKS.HEADING_2]: (_node, children) => <Typography variant="h2">{children}</Typography>,
  [BLOCKS.HEADING_3]: (_node, children) => <Typography variant="h3">{children}</Typography>,
  [BLOCKS.HEADING_4]: (_node, children) => <Typography variant="h4">{children}</Typography>,
  [BLOCKS.HEADING_5]: (_node, children) => <Typography variant="h5">{children}</Typography>,
  [BLOCKS.HEADING_6]: (_node, children) => (
    <Typography variant="h5" as="h6">
      {children}
    </Typography>
  ),
  [BLOCKS.PARAGRAPH]: (_node, children) => <p className="empty:hidden">{children}</p>,
  [BLOCKS.HR]: () => <hr />,
  [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
  [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
  [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
  [INLINES.HYPERLINK]: (node, children) => {
    const isInternal = isInternalHref(node.data.uri)
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <Link
        href={node.data.uri}
        target={isInternal ? '_self' : '_blank'}
        rel={isInternal ? '' : 'noopener noreferrer'}>
        {children}
      </Link>
    )
  },
  [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    if (
      !node.data.hasOwnProperty('target') ||
      !node.data.target.sys.hasOwnProperty('contentType')
    ) {
      return <span>{children}</span>
    }
    const basePath = contentTypeBasePathMap[node.data.target.sys.contentType.sys.id]
    const slug = node.data.target.fields.slug === indexPageSlug ? '/' : node.data.target.fields.slug
    const href = basePath === slug ? basePath : basePath + slug
    return <Link href={href}>{children}</Link>
  },
  [INLINES.EMBEDDED_ENTRY]: (node) => {
    const contentType = node.data.target.sys.contentType.sys.id
    if (contentType === 'icon') {
      return <Icon icon={node.data.target.fields.icon} />
    }
  },
  [INLINES.ASSET_HYPERLINK]: (node, children) => {
    const url = node.data.target?.fields?.file?.url ?? ''

    if (url.length) {
      return (
        <Link href={node.data?.target?.fields?.file?.url} target="_blank" rel="noopener noreferrer">
          {children}
        </Link>
      )
    } else {
      return children
    }
  },
}

export const renderText: Options['renderText'] = (text) =>
  text.split('\n').flatMap((text2, i) => [i > 0 && <br />, text2])

export const richTextRender = (content?: Document | Block | Inline) =>
  documentToReactComponents(content as Document, { renderMark, renderNode, renderText })
